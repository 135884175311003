import React, { useCallback, useEffect, useState } from "react";
import "./checkout-form.scss";
import CheckoutRequest, { CheckoutValidator } from "../../models/requests/CheckoutRequest";
import CheckoutResponse from "../../models/responses/CheckoutResponse";
import CheckoutService from "../../services/ui/checkout-service";
import StatefullInput from "../statefull-input/statefull-input";
import EventEmitter from "../../services/event/event-emitter";
import { IErrorResp } from "../../models/responses/IErrorResp";
import CartService, { CartItem } from "../../services/ui/cart-service";
import Price from "../price/price";
import StatefullLabel from "../statefull-label/statefull-label";
import LoadingComponent from "../loading-component/loading-component";
import { useNavigate } from "react-router-dom";
import { environment } from "../../environment/environment";
import KlarnaCheckout from "../klarna-checkout/klarna-checkout";
import { FaChevronLeft } from "react-icons/fa6";

export default function CheckoutForm(props: { checkoutResponse: (res: CheckoutResponse) => void }) {

  const navigation = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [cart] = useState<CartItem[]>(CartService.Cart());
  const [stateChangeEvent] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
  const [pageState, setPageState] = useState<'personal' | 'payment'>('personal');

  const [customerName, setCustomerName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [town, setTown] = useState('');
  const [county, setCounty] = useState('');
  const [postcode, setPostcode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [billingIsShipping, setBillingIsShipping] = useState(true);
  const [b_addressLine1, setBAddressLine1] = useState('');
  const [b_addressLine2, setBAddressLine2] = useState('');
  const [b_county, setBCounty] = useState('');
  const [b_town, setBTown] = useState('');
  const [b_postcode, setBPostcode] = useState('');

  const [checkoutRequest, setCheckoutRequest] = useState<CheckoutRequest>({
    customerName: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    county: '',
    postcode: '',
    phoneNumber: '',
    email: '',
    cardNumber: '',
    expiry: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    amount: 0,
    items: cart,
    checksum: '',
    billingIsShipping: true,
    b_addressLine1: '',
    b_addressLine2: '',
    b_county: '',
    b_town: '',
    b_postcode: ''
  } as CheckoutRequest);

  // useEffect(() => {
  //   console.log('upd', checkoutRequest);
  // }, [checkoutRequest])

  useEffect(() => {
    setCheckoutRequest({
      customerName: customerName,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      town: town,
      county: county,
      postcode: postcode,
      phoneNumber: phoneNumber,
      email: email,
      cardNumber: cardNumber,
      expiry: expiry,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear,
      cvv: cvv,
      amount: 0,
      items: cart,
      checksum: '',
      billingIsShipping: billingIsShipping,
      b_addressLine1: billingIsShipping ? addressLine1 : b_addressLine1,
      b_addressLine2: billingIsShipping ? addressLine2 : b_addressLine2,
      b_county: billingIsShipping ? county : b_county,
      b_town: billingIsShipping ? town : b_town,
      b_postcode: billingIsShipping ? postcode : b_postcode
    } as CheckoutRequest)
  }, [customerName, addressLine1, addressLine2, town, county, postcode, phoneNumber, email, cardNumber, expiry, expiryMonth, expiryYear, cvv, cart, b_addressLine1, b_addressLine2, b_county, b_town, b_postcode, billingIsShipping])

  const MakeKlarnaCheckoutRequest = useCallback((token: string, ref: string) => {
    setLoading(true);
    console.log("klarna make order", token)

    if (checkoutRequest === undefined) return;
    setLoading(true);

    // MAP AMOUNT TO REQUEST
    const request = { ...checkoutRequest };
    request.amount = CheckoutService.GetOrderTotal(checkoutRequest.postcode, cart).total;
    CheckoutService.CreateKlarnaOrder(request, token, ref, {
      success: (_: CheckoutResponse) => {
        if (_.status === 'declined') {
          setLoading(false);
          console.error('Unsuccessful', _);
          setTimeout(() => {
            stateChangeEvent.emit({
              error: 'Something went wrong', fixes: [
                { key: 'global', value: _.message || 'Something went wrong processing your payment' }
              ]
            } as IErrorResp)
          }, 250);
        } else {
          setLoading(false);
          console.log(_);
          if (_.status === 'accepted') {
            CartService.ClearCart();
            navigation(`/checkout/thank-you/${_.orderId}`);
          } else {
            setTimeout(() => {
              stateChangeEvent.emit({
                error: 'Something went wrong', fixes: [
                  { key: 'global', value: _.message || 'Something went wrong processing your payment' }
                ]
              } as IErrorResp)
            }, 250);
          }
        }
      },
      error: (err: any) => {
        setLoading(false);
        console.log(err);
      }
    })

  }, [cart, checkoutRequest, navigation, stateChangeEvent]);

  const HandleCheckoutPress = useCallback(() => {
    if (checkoutRequest === undefined) return;
    setLoading(true);

    // MAP AMOUNT TO REQUEST
    const request = { ...checkoutRequest };
    request.amount = CheckoutService.GetOrderTotal(checkoutRequest.postcode, cart).total;

    var validationResp = CheckoutValidator.ValidateAndBuild(request, 'all');
    if (!validationResp.status) {
      setLoading(false);
      stateChangeEvent.emit(validationResp.ToErrorResp())
      return;
    }

    CheckoutService.Checkout(validationResp.data, {
      success: (_: CheckoutResponse) => {
        if (_.status === 'declined') {
          setLoading(false);
          console.error('Unsuccessful', _);
          setTimeout(() => {
            stateChangeEvent.emit({
              error: 'Something went wrong', fixes: [
                { key: 'global', value: _.message || 'Something went wrong processing your payment' }
              ]
            } as IErrorResp)
          }, 250);
        } else {
          setLoading(false);
          console.log(_);
          if (_.status === 'accepted') {
            CartService.ClearCart();
            navigation(`/checkout/thank-you/${_.orderId}`);
          } else {
            props.checkoutResponse(_);
          }
        }

      },
      error: (err) => {
        setLoading(false);
        console.log(err);
      },
    })
  }, [checkoutRequest, cart, stateChangeEvent, navigation, props])

  const StartPaymentFlow = useCallback(() => {
    if (checkoutRequest === undefined) return;
    setLoading(true);

    // MAP AMOUNT TO REQUEST
    const request = { ...checkoutRequest };
    request.amount = CheckoutService.GetOrderTotal(checkoutRequest.postcode, cart).total;

    var validationResp = CheckoutValidator.ValidateAndBuild(request, 'personal');
    if (!validationResp.status) {
      setLoading(false);
      stateChangeEvent.emit(validationResp.ToErrorResp())
      return;
    }

    setLoading(false);
    setPageState('payment')
  }, [cart, checkoutRequest, stateChangeEvent]);

  return <div>
    {loading && <LoadingComponent />}
    {!loading && checkoutRequest && <div className="w-100" style={{ maxWidth: 800 }}>
      {pageState === 'personal' && <>
        <h2 className="mt-0">Customer Details</h2>
        <StatefullInput index={0} stateId={"customerName"} label={"Name *"} defaultValue={customerName} inputType={'text'} autocompleteType={'name'}
          onChangeCallback={(_) => {
            setCustomerName(_);
          }}
          stateChangeEvent={stateChangeEvent} />

        <div className="row mt-2">
          <StatefullInput index={1} classes="col-md-6 col-12" stateId={"phoneNumber"} label={"Mobile Number *"} defaultValue={phoneNumber} placeholder={'+44 7123456789'} inputType={'phone'} autocompleteType={""}
            onChangeCallback={(_) => {
              setPhoneNumber(_);
            }}
            stateChangeEvent={stateChangeEvent} />
          <StatefullInput classes="col-md-6 col-12" stateId={"email"} label={"Email *"} defaultValue={email} placeholder={"info@example.com"} inputType={'email'} autocompleteType={""}
            onChangeCallback={(_) => {
              setEmail(_)
            }}
            stateChangeEvent={stateChangeEvent} />
        </div>

        <StatefullInput classes="mt-2" stateId={"addressLine1"} label={"Address Line 1 *"} defaultValue={addressLine1} inputType={'text'} autocompleteType={""}
          onChangeCallback={(_) => {
            setAddressLine1(_);
          }}
          stateChangeEvent={stateChangeEvent} />

        <StatefullInput classes="mt-2" stateId={"addressLine2"} label={"Address Line 2"} defaultValue={addressLine2} inputType={'text'} autocompleteType={""}
          onChangeCallback={(_) => {
            setAddressLine2(_);
          }}
          stateChangeEvent={stateChangeEvent} />

        <div className="row mt-2">
          <StatefullInput classes="col-12 col-md-4" stateId={"town"} label={"Town"} defaultValue={town} inputType={'text'} autocompleteType={""}
            onChangeCallback={(_) => {
              setTown(_);
            }}
            stateChangeEvent={stateChangeEvent} />
          <StatefullInput classes="col-12 col-md-4" stateId={"county"} label={"County"} defaultValue={county} inputType={'text'} autocompleteType={""}
            onChangeCallback={(_) => {
              setCounty(_);
            }}
            stateChangeEvent={stateChangeEvent} />

          <StatefullInput classes="col-12 col-md-4" stateId={"postcode"} label={"Postcode *"} defaultValue={postcode} inputType={'text'} autocompleteType={""}
            onChangeCallback={(_) => {
              setPostcode(_);
            }}
            stateChangeEvent={stateChangeEvent} />
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-start gap-1">
          <input type="checkbox" id="billingIsShippingInput" checked={billingIsShipping} onChange={(_) => {
            setBillingIsShipping(!billingIsShipping);
          }} />
          <label htmlFor={`billingIsShippingInput`} className="form-label mb-0">Billing address same as shipping?</label>
        </div>

        {!billingIsShipping && <>
          <h2 className="mt-4">Billing Address</h2>
          <StatefullInput classes="mt-2" stateId={"b_addressLine1"} label={"Address Line 1 *"} defaultValue={b_addressLine1} inputType={'text'} autocompleteType={""}
            onChangeCallback={(_) => {
              setBAddressLine1(_);
            }}
            stateChangeEvent={stateChangeEvent} />

          <StatefullInput classes="mt-2" stateId={"b_addressLine2"} label={"Address Line 2"} defaultValue={b_addressLine2} inputType={'text'} autocompleteType={""}
            onChangeCallback={(_) => {
              setBAddressLine2(_);
            }}
            stateChangeEvent={stateChangeEvent} />

          <div className="row mt-2">
            <StatefullInput classes="col-12 col-md-4" stateId={"b_town"} label={"Town"} defaultValue={b_town} inputType={'text'} autocompleteType={""}
              onChangeCallback={(_) => {
                setBTown(_);
              }}
              stateChangeEvent={stateChangeEvent} />
            <StatefullInput classes="col-12 col-md-4" stateId={"b_county"} label={"County"} defaultValue={b_county} inputType={'text'} autocompleteType={""}
              onChangeCallback={(_) => {
                setBCounty(_);
              }}
              stateChangeEvent={stateChangeEvent} />

            <StatefullInput classes="col-12 col-md-4" stateId={"b_postcode"} label={"Postcode *"} defaultValue={b_postcode} inputType={'text'} autocompleteType={""}
              onChangeCallback={(_) => {
                setBPostcode(_);
              }}
              stateChangeEvent={stateChangeEvent} />
          </div></>
        }

        <div className="mt-4">
          <button className="ms-auto me-0 d-block mt-1 btn btn-warning border-0 btn-lg w-100" style={{ maxWidth: 300 }} onClick={() => StartPaymentFlow()}>Continue</button>
        </div>
      </>}

      {pageState === 'payment' && <>
        <div className="mt-4">
          <button className="ms-0 me-auto d-flex align-items-center justify-content-center btn btn-dark" style={{ maxWidth: 300 }} onClick={() => setPageState('personal')}>
            <FaChevronLeft className="fs-6 my-0" />
            <span className="fs-6 my-0">Back</span>
          </button>
        </div>

        <div>
          {environment.klarna && <KlarnaCheckout request={{ ...checkoutRequest }} callback={(authorization_token: string, ref: string) => { MakeKlarnaCheckoutRequest(authorization_token, ref) }} />}
        </div>

        <h2 className="mt-2">Card Details</h2>
        <div className="row">
          <StatefullInput classes="col-md-6 col-12" stateId={"cardNumber"} label={"Card Number *"} defaultValue={cardNumber} inputType={'number'} autocompleteType={"cc-number"}
            onChangeCallback={(_) => {
              setCardNumber(_)
            }}
            stateChangeEvent={stateChangeEvent} />
          <StatefullInput classes="col-md-3 col-6" stateId={"expiry"} label={"Expiry *"} defaultValue={expiry === '' ? '' : `${expiryMonth} / ${expiryYear}`} placeholder={"MM / YY"} inputType={'text'} autocompleteType={"cc-exp"}
            onPressWithChange={(_: any, forceChange: EventEmitter<string>) => {
              var expiry = CheckoutValidator.ExpiryBuilder(_, checkoutRequest.expiry);
              if (expiry === undefined || expiry === ' / ') {
                setExpiry(' / ');
                return;
              }
              forceChange.emit(expiry);
              setExpiry(expiry);
              try {
                setExpiryMonth(expiry.split(' / ')[0])
                setExpiryYear(expiry.split(' / ')[1])
              } catch {
                console.log(expiry, 'cant be parsed');
              }
            }}
            onChangeCallback={(_) => {
              console.log('expiry change', _);
              try {
                setExpiry(_);
                setExpiryMonth(_.split(' / ')[0])
                setExpiryYear(_.split(' / ')[1])
              } catch {
                console.log(_, 'cant be parsed');
              }
            }}
            stateChangeEvent={stateChangeEvent} />

          <StatefullInput classes="col-md-3 col-6" maxLength={4} stateId={"cvv"} label={"CVV *"} defaultValue={cvv} placeholder={"123"} inputType={'number'} autocompleteType={"cc-csc"}
            onChangeCallback={(_) => {
              setCvv(_);
            }}
            stateChangeEvent={stateChangeEvent} />
        </div>
      </>
      }
      <div className="mt-5">
        {cart.map((ci: CartItem, i: number) => {
          return <div key={i} className="d-flex align-items-center justify-content-end gap-2">
            <p className="my-0">{ci.variant.title} ({ci.variant.postageProvider}):</p><Price showFree={true} price={CheckoutService.GetShippingPrice(checkoutRequest.postcode, ci)} currencyCode={"GBP"} />
          </div>
        })}

        <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
          <p className="fw-bold fs-md-20 fs-mb-18 my-0">Total:</p>
          <Price classes="fs-md-20 fs-mb-18" price={CheckoutService.GetOrderTotal(checkoutRequest.postcode, cart).compareAt} currencyCode={"GBP"} />
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2 mt-1">
          {environment.discount > 0 && <p className="fs-5 lh-sm fw-bold">Black friday discount has been applied!</p>}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-end gap-3">
          <StatefullLabel stateId={'global'} stateChangeEvent={stateChangeEvent} />
        </div>
        {pageState === 'payment' && <button className="ms-auto me-0 d-block mt-1 btn btn-warning border-0 btn-lg w-100" style={{ maxWidth: 300 }} onClick={() => HandleCheckoutPress()}>PAY NOW</button>}
      </div>

      <div className="mt-4"><div id="klarna-payments-container"></div></div>
    </div>
    }
  </div>;
}


