import React, { useCallback, useEffect, useState } from "react";
import "./three-ds-form.scss";
import CheckoutResponse from "../../models/responses/CheckoutResponse";
import CheckoutService from "../../services/ui/checkout-service";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../loading-component/loading-component";
export default function ThreeDsForm(props: { checkoutResponse: CheckoutResponse }) {

  const navigation = useNavigate();
  const [threeDSRequest, setThreeDSRequest] = useState<{ threeDSRef: string, threeDSMethodData: string }>();
  const [threeDSResponse, setThreeDSResponse] = useState<CheckoutResponse>();

  useEffect(() => {
    setThreeDSRequest(undefined);
    setThreeDSResponse(undefined);
  }, []);

  useEffect(() => {
    if (props.checkoutResponse === undefined) return;
    const req = { threeDSRef: props.checkoutResponse.threeDSRef, threeDSMethodData: props.checkoutResponse.threeDSRequest.threeDSMethodData };
    CheckoutService.Log({ type: 'set request', req: req });
    setThreeDSRequest(req);
  }, [props])

  const Handle3DSPress = useCallback((request: { threeDSRef: string, threeDSMethodData: string }) => {
    if (request === undefined) {
      CheckoutService.Log({ type: 'ln:24 no request' });
      return;
    }
    CheckoutService.Complete3DS(request, {
      success(_) {
        CheckoutService.Log({ threeDsResp: _.status, ref: _.threeDSRef, creq: _.threeDSRequest.creq, misc: _.threeDSURL });
        setThreeDSResponse(_);
      },
      error(err) {
        navigation('/checkout/complete?status=declined');
        console.log(err);
      },
    })
  }, [navigation])

  useEffect(() => {
    if (threeDSResponse === undefined) {
      CheckoutService.Log({ type: 'ln:38 no response' });
      return;
    }
    if (threeDSResponse.status === 'declined') {
      navigation('/checkout/complete?status=declined');
      return;
    }
    setTimeout(() => {
      var form = document.getElementById('3ds_form_trigger');
      CheckoutService.Log({ type: '3ds form trigger', form: form });
      if (form === null) return;
      (form as any).submit();
    }, 1000);
  }, [navigation, threeDSResponse]);

  useEffect(() => {
    if (threeDSRequest === undefined) {
      CheckoutService.Log({ type: 'ln:55 no request' });
      return;
    }
    Handle3DSPress(threeDSRequest);
  }, [threeDSRequest, Handle3DSPress]);

  return <div>
    <LoadingComponent />
    <div className="" style={{ maxWidth: 0, maxHeight: 0 }}>
      {threeDSResponse === undefined && <>
        {/* <button className="btn btn-warning border-0 btn-lg w-100" style={{ maxWidth: 300 }} onClick={() => Handle3DSPress()}>DO 3DS</button> */}
        <pre>
          {JSON.stringify(props.checkoutResponse, null, 4)}
        </pre>
      </>}
      {threeDSResponse !== undefined && threeDSResponse.threeDSRequest !== undefined && <>
        <form action={threeDSResponse.threeDSURL} method="POST" id="3ds_form_trigger">
          <input type="hidden" name="threeDSRef" readOnly id="threeDSRef" value={threeDSResponse.threeDSRef} />
          <input type="hidden" name="creq" id="creq" readOnly value={threeDSResponse.threeDSRequest.creq} />
        </form>
      </>}
      <pre>
        {JSON.stringify(props.checkoutResponse, null, 4)}
      </pre>
      <pre>
        {JSON.stringify(threeDSRequest, null, 4)}
      </pre>
      <pre>
        {JSON.stringify(threeDSResponse, null, 4)}
      </pre>
    </div>
  </div>;
}


