import React, { useEffect, useRef, useState } from "react";
import "./nav-header.scss";
import { Link } from "react-router-dom";
import { FaArrowRightToBracket, FaBagShopping, FaBars, FaCircleUser, FaMagnifyingGlass, FaUserShield } from 'react-icons/fa6';
import UserService from "../../services/session/user-service";
import { AccountType } from "../../models/data/user-session";
import { useDebounce } from 'use-debounce';
import ProductService from "../../services/ui/product-service";
import ProductEntity from "../../models/data/product";
import Price from "../price/price";
import CartService, { CartItem } from "../../services/ui/cart-service";

function useOutsideAlerter(ref: any, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default function NavHeader() {

  const [isAdmin] = useState<boolean>(UserService.getCurrentSession()?.accountType === AccountType.Admin);
  const [search, setSearch] = useState<string>('');
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchQuery] = useDebounce(search, 750);
  const [searchResults, setSearchResults] = useState<{ status: boolean, results: ProductEntity[] } | undefined>(undefined);
  const [cartItems, setCartItems] = useState<CartItem[]>(CartService.Cart());

  useEffect(() => {
    const cue = CartService.CART_CHANGED.subscribe((event: { latestAdd: string | undefined, items: CartItem[] }) => {
      if (event.latestAdd !== undefined) console.log('show modal for', event.latestAdd);
      setCartItems(event.items);
    });
    return () => {
      CartService.CART_CHANGED.unsubscribe(cue);
    }
  }, [])

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setSearchOpen(false);
  });

  useEffect(() => {
    if (searchQuery === '') return;
    ProductService.SearchProducts(searchQuery, {
      success: function (_: ProductEntity[]): void {
        setSearchResults({ status: true, results: _ });
      },
      error: function (err: any): void {
        console.log('error');
        setSearchResults({ status: false, results: [] });
      }
    })
  }, [searchQuery]);

  useEffect(() => {
    if (search !== '') setSearchOpen(true);
  }, [search])

  // const CartUpdatedModal = () => {
  //   return <div className="search-dialog position-absolute py-2 top-100 start-0 end-0 bg-white rounded-3 border" style={{ transform: 'translate(0px, 10px)', zIndex: 10000 }}>
  //     <p>Cart Updated</p>
  //   </div>
  // }

  return <>
    <div className="container-fluid px-0" style={{ background: '#0B051D' }}>
      <img src="https://cdn-moddelo.s3.eu-west-2.amazonaws.com/site-media/54e9e10f-fa43-4660-8dac-4f1a0110b6af_970x90.png" className="d-block w-100 mx-auto" alt="Pay With Klarna" style={{ maxWidth: 600}}/>
    </div>
    <div className="container-fluid px-0 bg-light">

      {/*<div className="py-2 px-3 px-md-0 bg-danger">
        <div className="container d-flex align-items-center justify-content-center gap-3 pe-0">
          <p className="my-0 fw-bold fs-5 text-center text-white">BLACK FRIDAY SALE NOW ON - 5% OFF SITE WIDE</p>
        </div>
      </div>*/}

      <div className="py-2 px-3 px-md-0 text-dark bg-warning">
        <div className="container d-flex align-items-center justify-content-end gap-3 pe-0">
          <a href="tel:01785719005">01785 719005</a>
          {UserService.isLoggedIn() ? <Link to={"/account"}><FaCircleUser /> Account</Link> : <Link to={"/login"}><FaArrowRightToBracket /> Login</Link>}
          {UserService.isLoggedIn() && isAdmin && <Link to={"/admin"}><FaUserShield /> Admin</Link>}
        </div>
      </div>
      <div className="py-2 container">
        <div className="row px-3 px-sm-0">

          {/* XL 2 / LG 12*/}
          <Link to={"/"} className="col-10 col-md-4 col-xl-3 d-flex align-items-center justify-content-between ps-0">
            <img src="https://dosirc917rnix.cloudfront.net/site-media/99f6b6b2-cbb2-43cf-b074-08d94d8771b2_logo.png" height="50" className="d-inline-block align-top" alt="logo" />
          </Link>


          {/* XL 9 / LG 8 */}
          <div ref={wrapperRef} className="col-11 col-md-7 col-xl-9 d-none d-md-flex align-items-center justify-content-between pe-0 ps-0 position-relative">
            <div className="rounded-pill border border-2 border-dark mx-0 bg-light w-100 overflow-hidden d-flex align-items-stretch justify-content-between" onClick={() => setSearchOpen(true)}>
              <input className="flex-fill w-100 px-4 border-0 py-2 bg-transparent" placeholder="Search..." value={search} onChange={(event) => {
                setSearch(event.target.value);
              }} />
              <div className="pe-3 d-flex align-items-center">
                <FaMagnifyingGlass />
              </div>
            </div>

            {search !== '' && searchOpen && <div className="search-dialog position-absolute py-2 top-100 start-0 end-0 bg-white rounded-3 border" style={{ transform: 'translate(0px, 10px)', zIndex: 10000 }}>
              <p className="px-3 fw-bolder">'{search}' Results</p>
              <div>
                {searchResults && (!searchResults.status || searchResults.results.length === 0) && <p className="px-3">No Results Found.</p>}
                {searchResults && searchResults.status && searchResults.results.length > 0 && <div className="overflow-auto" style={{ maxHeight: 400 }}>
                  {searchResults.results.map((p, i) => {
                    return <a key={`search-result-${i}`} onClick={() => { setSearchOpen(false); setSearch(''); }} className="border-bottom w-100 p-2 d-flex align-items-start justify-content-start gap-2" href={`/product/${p.handle}`}>
                      <img loading={'lazy'} src={p.featuredMedia} className="w-100 d-block" alt={p.title} style={{ maxWidth: 150 }} />
                      <div>
                        <p className="my-0 w-100 text-truncate" style={{ maxWidth: 300 }}>{p.title}</p>
                        <Price price={p.price} currencyCode="GBP" classes="fw-bolder" />
                      </div>
                    </a>
                  })}
                </div>}
              </div>
            </div>}
          </div>

          {/* XL 12 / LG  */}
          <div className="d-flex align-items-center justify-content-end d-xl-none col-md-1 col-2 pe-0">
            <div className="position-relative">
              <FaBars className="fs-2" data-bs-toggle="offcanvas" data-bs-target="#sidenav" aria-controls="sidenav" style={{ cursor: 'pointer' }} />
              {cartItems !== undefined && cartItems.length > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {cartItems.length}
                <span className="visually-hidden">unread messages</span>
              </span>}
            </div>
          </div>
        </div>

        <div className="row mt-4 d-none d-xl-block">
          <div className="d-flex align-items-center justify-content-between px-0 flex-wrap gap-x-2">
            <Link to={"/"}>Home</Link>
            <Link to={"/collections/electric-fireplace-suites"}>Electric Fireplace Suites</Link>
            <Link to={"/collections/wall-mounted-electric-fires"}>Wall Mounted Electric Fires</Link>
            <Link to={"/collections/electric-insert-fires"}>Electric Insert Fires</Link>
            <Link to={"/collections/electric-stoves"}>Electric Stoves</Link>
            <Link to={"/collections/accessories"}>Fireplace Accessories</Link>
            {/* <Link to={"/"}>Blog</Link> */}
            <div className="position-relative">
              <Link to={"/cart"} className="d-flex align-items-center justify-content-center gap-1">
                <FaBagShopping />
                Cart
              </Link>
              {cartItems !== undefined && cartItems.length > 0 && <span className="position-absolute top-0 start-0 translate-middle badge rounded-circle bg-danger">
                {cartItems.length}
                <span className="visually-hidden">unread messages</span>
              </span>}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="offcanvas offcanvas-end" tabIndex={-1} id="sidenav" aria-labelledby="sidenavLabel">
      <div className="offcanvas-header">
        <h5 id="sidenavLabel">FLAMME</h5>
        <button type="button" className="btn-close text-reset text-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div className="d-flex flex-column align-items-start justify-content-start gap-1">
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/"}>Home</Link>
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/collections/electric-fireplace-suites"}>Electric Fireplace Suites</Link>
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/collections/wall-mounted-electric-fires"}>Wall Mounted Electric Fires</Link>
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/collections/electric-insert-fires"}>Electric Insert Fires</Link>
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/collections/electric-stoves"}>Electric Stoves</Link>
          <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/collections/accessories"}>Fireplace Accessories</Link>
          {/* <Link to={"/"}>Blog</Link> */}
          <div className="position-relative">
            <Link data-bs-dismiss="offcanvas" aria-label="Close" to={"/cart"} className="d-flex align-items-center justify-content-center gap-1">
              <FaBagShopping />
              Cart ({cartItems.length})
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>;
}


