import React, { useCallback, useEffect, useState } from "react";
import "./klarna-checkout.scss";
import CheckoutService from "../../services/ui/checkout-service";
import CheckoutRequest from "../../models/requests/CheckoutRequest";
import CartService, { CartItem } from "../../services/ui/cart-service";

class KlarnaOption {
  public asset_urls: {
    descriptive: string,
    standard: string
  };
  public identifier: string;
  public name: string
}

export default function KlarnaCheckout(props: { request: CheckoutRequest, callback: (token: string, ref: string) => void }) {

  const [loading, setLoading] = useState<boolean>(false);
  const [orderRef, setOrderRef] = useState<string>();
  const [showKlarnaOption, setShowKlarnaOption] = useState<boolean>(false);
  const [klarnaOptions, setKlarnaOptions] = useState<KlarnaOption[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [cart] = useState<CartItem[]>(CartService.Cart());
  const [checkoutRequest] = useState<CheckoutRequest>(props.request);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://x.klarnacdn.net/kp/lib/v1/api.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const InitPaymentWithToken = useCallback((token: string) => {
    (window as any).Klarna.Payments.init({
      client_token: token
    });
    LoadKlarnaWindow();
  }, [])

  const LoadKlarnaWindow = () => {
    (window as any).Klarna.Payments.load(
      {
        container: '#klarna-payments-container'
      },
      {},
      (res: any) => {
        if (res.show_form) {
          setShowKlarnaOption(true);
        } else {
          setShowKlarnaOption(false);
          console.error("Dont show klarna")
        }
      }
    )
  }
  const CreateSession = useCallback(() => {
    setLoading(true);
    setOrderRef(undefined);
    const request = { ...checkoutRequest };
    request.amount = CheckoutService.GetOrderTotal(request.postcode, cart).total;

    CheckoutService.CreateKlarnaSession(request, {
      success: function (_: any): void {
        setLoading(false);
        console.log(_)
        setOrderRef(_.ref)
        setKlarnaOptions(_.payment_method_categories as KlarnaOption[]);
        InitPaymentWithToken(_.client_token)
      },
      error: function (err: any): void {
        setLoading(false);
        setShowKlarnaOption(false);
        setKlarnaOptions([]);
        console.log(err);
      }
    })
  }, [InitPaymentWithToken, cart, checkoutRequest])

  const AuthSession = useCallback((method: string) => {
    if (loading) return;
    if (orderRef === undefined) return;
    setErrorMessage(undefined);
    setLoading(true);
    console.log("Auth session with method", method);
    (window as any).Klarna.Payments.authorize({
      payment_method_category: method
    },
      {},
      (res: { approved: boolean, show_form: boolean, authorization_token: string, error: any }) => {
        if (!res.show_form) { setShowKlarnaOption(false); setKlarnaOptions([]); }
        if (!res.approved) { if (res.error === undefined) { setLoading(false); return; } else { setLoading(false); setErrorMessage("Something went wrong"); console.error(res.error); } }
        if (res.approved) { props.callback(res.authorization_token, orderRef); }
        setLoading(false);
      });
  }, [loading, props, orderRef])

  useEffect(() => {
    CreateSession()
  }, [CreateSession]);

  return <>
    {showKlarnaOption && <>
      <div className={`mt-4 ${loading ? 'opacity-5' : ''}`}>
        <p className="mb-1 fs-5">Continue with <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" className="d-inline" alt="Klarna" /></p>
        <div className="d-block d-md-flex gap-2">
          {klarnaOptions.map((ko: KlarnaOption, i: number) => {
            return <button key={`${i}_klarna_option`} className="d-block w-100 p-2 mb-2 btn rounded-3" style={{ background: '#FFA8CD' }} onClick={() => AuthSession(ko.identifier)}>
              <div className="d-flex align-items-center justify-content-start gap-1">
                <span className="d-block text-center w-100 my-0 fs-6 lh-sm">{ko.name}</span>
              </div>
            </button>
          })}
        </div>
      </div>
    </>
    }

    {errorMessage !== undefined && errorMessage.length > 0 && <p className="text-danger mt-2">{errorMessage}</p>}

    {showKlarnaOption && <div className="d-flex align-items-center justify-content-center">
      <div className="bg-dark w-100" style={{ height: 1 }}></div>
      <p className="fs-5 d-block my-0 mx-3">OR</p>
      <div className="bg-dark w-100" style={{ height: 1 }}></div>
    </div>
    }
  </>;
}
